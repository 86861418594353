.toaster {
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 24px;
    width: min(calc(100% - 48px), 400px);
}

.toast {
    display: flex;
    align-items: flex-start;
    width: 100%;
}