.table {
    width: min(1400px, 100%);
    margin: auto;
}

.table .table__header {
    z-index: 20;
    position: sticky;
    top: 0;
    background-color: black;
}

.table .table__player-col {
    z-index: 10;
    position: sticky;
    left: 0;
}
.table td.table__player-col {
    padding-right: 2px;
}

.table tbody .table__player-col.--knocked-out {
    background-color: #fac99e;
}

.table tbody tr:nth-child(even) .table__player-col.--knocked-out {
    background-color: #deaa7c;
}

.table tbody .table__player-col:not(.--knocked-out) {
    background-color: #9edbfa;
}

.table tbody tr:nth-child(even) .table__player-col:not(.--knocked-out) {
    background-color: #7cbede;
}

.table th {
    color: white;
    background-color: black;
    min-width: max-content;
    padding: 2px 8px;
}

.table th:not(:last-child) {
    border-right: 1px solid white;
}

.table td {
    color: black;
    font-family: monospace;
    min-width: max-content;
    padding: 2px 6px;
    border: 1px solid black;
    height: 32px;
}

.table tbody tr:nth-child(odd) td {
    background-color: white;
}

.table tbody tr:nth-child(even) td {
    background-color: #ededed;
}

@media (max-width: 400px) {
    .table {
        font-size: 0.8rem;
    }
    .table td {
        height: 28px;
    }
}