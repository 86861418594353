.table .table__pick-header {
    display: inline-block;
    min-width: 64px;
}

.table .table__center {
    text-align: center;
}

.table .table__pick {
    white-space: nowrap;
}

.table tbody td.table__pick.--yes {
    background-color: #a3faa0;
}

.table tbody tr:nth-child(even) td.table__pick.--yes {
    background-color: #7ede7c;
}

.table tbody td.table__pick.--no {
    background-color: #faa0a0;
}

.table tbody tr:nth-child(even) td.table__pick.--no {
    background-color: #de7c7c;
}

.table tbody td.table__pick.--error {
    background-color: #edfaa0;
}

.table tbody tr:nth-child(even) td.table__pick.--error {
    background-color: #cfde7c;
}
